import '../styles/Navbar.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { palette } from '../styles/palettes';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';

interface NavbarSection {
    route: string;
    name: string;
}

export default function Navbar() {

    // ------ States -------
    let [sections] = useState<NavbarSection[]>([
        {
            route: '/',
            name: 'HOME',
        },
        {
            route: '/gallery',
            name: 'GALLERY',
        },
        {
            route: '/services',
            name: 'SERVICES',
        },
        {
            route: '/about',
            name: 'ABOUT',
        },
    ]);
    // const [type, setType] = useState<string>("default");
    const [title] = useState<string>("M & W NAIL");
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [showAlert] = useState<boolean>(false);
    const [alertText] = useState<string>("We are closed from July 4 to July 8 (5 days)");

    // --------- Icon for the expand drawer button on smaller screens ------
    const iconMenu = (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 9C3 8.17157 3.67157 7.5 4.5 7.5H31.5C32.3284 7.5 33 8.17157 33 9C33 9.82843 32.3284 10.5 31.5 10.5H4.5C3.67157 10.5 3 9.82843 3 9Z" fill="#2D3648"/>
            <path d="M3 18C3 17.1716 3.67157 16.5 4.5 16.5H31.5C32.3284 16.5 33 17.1716 33 18C33 18.8284 32.3284 19.5 31.5 19.5H4.5C3.67157 19.5 3 18.8284 3 18Z" fill="#2D3648"/>
            <path d="M4.5 25.5C3.67157 25.5 3 26.1716 3 27C3 27.8284 3.67157 28.5 4.5 28.5H31.5C32.3284 28.5 33 27.8284 33 27C33 26.1716 32.3284 25.5 31.5 25.5H4.5Z" fill="#2D3648"/>
        </svg>
    )

    const list = (
        <Box
            sx={{ width: 250 }}
            role="presentation"

            onClick={() => {setDrawerOpen(false)}}
            onKeyDown={(event) => {
                if((event.key === 'Tab' || event.key === 'Shift'))
                    return;
                setDrawerOpen(false);
            }}
        >
            <List>
                {sections.map((section) => (
                    <Link key={section.name} to={section.route} style={{
                        textDecoration: "none",
                    }}>
                        <ListItem disablePadding>
                            <ListItemButton sx={{padding:'20px 20px'}}>
                                {/* <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon> */}
                                <Typography variant='h4' component='div'>
                                    {section.name}
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box>
    );

    const alertBar = showAlert? (
        <Typography className="navbarAlertBar" variant="body1" component="div">
            {alertText}
        </Typography>
    ):null;

    return (
        <Box className="navbarContainer">
            <Box className="navbarContent">
                <Typography className="navbarTitle" variant="h3" component="div">
                    {title}
                </Typography>
                <Box className="navbarButtonsContainer">
                    {
                        sections.map((section) => {
                            return (
                                <Link key={section.name} to={section.route} style={{
                                    textDecoration: "none",
                                }}>
                                    <Button>
                                        <Typography variant="h4" component="div" sx={{
                                            color:palette.neutral[700],
                                        }}>
                                            {section.name}
                                        </Typography>
                                    </Button>
                                </Link>
                            );
                        })
                    }   
                </Box>
                <Box className="navbarRightPadding"></Box>
                <Box className="navbarDrawerContainer">
                    <Button className="navbarOpenDrawerButton" onClick={() => setDrawerOpen(true)}>
                        {iconMenu}
                    </Button>
                    <Drawer
                        anchor={'right'}
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                    >
                    {list}
                    </Drawer>
                </Box>
            </Box>
            {alertBar}
        </Box>
    );
}

