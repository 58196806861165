import "../styles/Services.css";
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ServiceItem from './ServiceItem';

interface IServiceItem {
    name: string;
    price: number;
}

interface ServiceSection {
    title: string;
    contents: IServiceItem[];
}

export default function Services() {
    const [servicesTitle] = useState("Services");
    const [servicesText] = useState("We accept cash and card");
    const [sections] = useState<ServiceSection[]>([
        {
            title: "NAILS",
            contents: [
                {
                    name: "Gel-X",
                    price: 68
                },
                {
                    name: "Manicure",
                    price: 16,
                },
                {
                    name: "Pedicure",
                    price: 25,
                },
                {
                    name: "Manicure & Pedicure",
                    price: 38,
                },
                {
                    name: "SNS Powder Gel",
                    price: 47,
                },
                {
                    name: "Buffing Manicure",
                    price: 24,
                },
                {
                    name: "Spa Pedicure",
                    price: 40,
                },
                {
                    name: "Special Pedicure",
                    price: 30,
                },
                {
                    name: "Color Gel",
                    price: 30,
                },
                {
                    name: "Polish Change - Hand",
                    price: 13,
                },
                {
                    name: "Polish Change - Feet",
                    price: 13,
                },
                {
                    name: "Gel Pedicure",
                    price: 40,
                },
                {
                    name: "Acrylic Tip or Wrapping",
                    price: 36,
                },
                {
                    name: "Acrylic Extension",
                    price: 45,
                },
                {
                    name: "French Tip",
                    price: 41,
                },
                {
                    name: "Acrylic Fill In",
                    price: 28,
                },
                {
                    name: "Silk Wrapping",
                    price: 49,
                },
                {
                    name: "Silk Extension",
                    price: 59,
                },
                {
                    name: "Silk Fill-In",
                    price: 39,
                },
                {
                    name: "UV Gel Full",
                    price: 58,
                },
                {
                    name: "UV Gel Fill",
                    price: 48,
                },
                {
                    name: "Nails Take-Off",
                    price: 16,
                },
                {
                    name: "Cut Down",
                    price: 5,
                },
                
            ]
        },
        {
            title: "WAXING",
            contents: [
                {
                    name: "Eyebrow",
                    price: 8,
                },
                {
                    name: "Lip",
                    price: 8,
                },
                {
                    name: "Chin",
                    price: 10,
                },
                {
                    name: "Whole Face",
                    price: 25,
                },
            ]
        },
        {
            title: "MASSAGE",
            contents: [
                {
                    name: "10 Minutes",
                    price: 10,
                },
                
            ]
        }
    ]);

    return (
        <Box className="servicesContainer">
            <Box className="servicesTitleContainer">
                <Typography variant='h1' component='div'>{servicesTitle}</Typography>
                <Typography variant='body2' component='div'>{servicesText}</Typography>
            </Box>
            {
                sections.map((section) => {
                    return (
                        <Box className="servicesSection" key={section.title}>
                            <Typography variant='h2' component='div'>{section.title}</Typography>
                            <Box className="servicesSectionItemsContainer">
                                {
                                    section.contents.map((content) => {
                                        return <ServiceItem key={content.name} name={content.name} price={content.price}/>
                                    })
                                }
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    );
}
